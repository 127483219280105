import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../components/SiteMetadata'

import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner/PageBanner'

const MerchPage = ({ data }) => {
  const { title } = useSiteMetadata()
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{`${frontmatter.title} | ${title}`}</title>
      </Helmet>
      <PageBanner title={ frontmatter.title } />

      <section>

        <div className="container min-h-screen pb-48">
          <div className="grid lg: grid-cols-6 lg:grid-cols-12 gap-40">            
            {frontmatter.merchLinks.map((item) => {
              return (
                <div className="col-span-6 lg:col-span-6 text-center">
                  <a href={ item.url } target="_blank" rel="noreferrer" className="block bg-gray-500 py-72 lg:py-176 hover:text-red">
                    <span className="text-24 lg:text-32 font-serif">{ item.title }</span>
                  </a>
                </div>
              )
            })}
          </div>

        </div>

      </section>

    </Layout>
  )
}

MerchPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MerchPage

export const pageQuery = graphql`
  query MerchPage {
    markdownRemark(frontmatter: { templateKey: { eq: "merch-page" } }) {
      frontmatter {
        title
        merchLinks {
          url
          title
        }
      }
    }
  }
`
